<template>
  <div class="popup-add-object">
    <div class="popup-add-object__wrapper">
      <Column :class="$viewport.desktop ? 'grid-6' : ''">
        <p class="popup-add-object__title">{{ title }}</p>
        <p class="popup-add-object__description">Вы можете оставить данные любимого объекта.
          Благодарим за Ваш интерес к проекту. Давайте открывать Томскую область вместе!</p>
        <SelectComponent id="objectTypeId"
                         label="Категория"
                         itemKey="name"
                         :index="objectTypeId"
                         v-on:change-index="changeIndexType"
                         :array="$types"/>
        <SelectComponent id="objectCityId"
                         label="Выберите населенный пункт"
                         itemKey="name"
                         :index="objectCityId"
                         v-on:change-index="changeIndexCity"
                         :array="$cities.data"/>
        <InputComponent id="objectTitle"
                        label="Название объекта"
                        type="text"
                        v-model="objectTitle"/>
        <InputComponent id="objectAnnotation"
                        v-model="objectAnnotation"
                        label="Аннотация"
                        type="text"
                        maxChars="700"
                        multiline/>
        <InputComponent id="objectDescription"
                        v-model="objectDescription"
                        label="Описание"
                        type="text"
                        maxChars="700"
                        multiline/>
        <label class="popup-add-object__label"
               for="objectAddress">Адрес</label>
        <input class="popup-add-object__input"
               id="objectAddress"
               type="text"
               v-model="objectAddress"
               @input="onAddressInput">
        <div class="popup-add-object__add-point">
          <span class="popup-add-object__add-point-text">указать точку на карте</span>
          <Icon class="popup-add-object__add-point-icon"
                :rotate="90"
                viewport="0 0 20 20"
                xlink="arrow-navigation"/>
        </div>
        <div id="map"
             :style="{width, height}">
          <yandex-map :controls="[]"
                      :options="{suppressMapOpenBlock:true}"
                      :coords="coords"
                      :zoom="zoom"
                      :settings="settings"
                      :style="{width:'100%', height:'100%'}"
                      @click="onMapClick">
            <ymap-marker
              marker-id="selectedCoords"
              :coords="coords"
              :icon="markerIcon"
            />

          </yandex-map>
        </div>
        <InputComponent id="objectContacts"
                        label="Контакты"
                        type="text"
                        v-model="objectContacts"/>
        <Info text="Вы можете указать любую полезную контактную информацию (телефон, сайт, график
        работы, запись на экскурсии)."/>
        <InputComponent id="objectYouTube"
                        label="Ссылка на видео"
                        type="text"
                        :error="errorObjectYouTube"
                        v-model="objectYouTube"/>
        <AddFile
          :params="{label: 'Фотографии', uploadedImages: $popup.additions.images || []}"
          v-on:change-preview="changePreview"
          v-on:change-file="changeFile"
        />
        <div>
          <Button color="green"
                  :disabled="disabled"
                  v-on:click.native="addObject">Добавить объект
          </Button>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PopupAddObject',
    data() {
      return {
        files: [],
        previews: [],
        objectTitle: '',
        objectDescription: '',
        objectAnnotation: '',
        objectTypeId: 1,
        objectAddress: '',
        objectContacts: '',
        objectYouTube: '',
        errorObjectYouTube: '',
        objectCityId: 1,
        disabled: true,
        params: {
          sizeMap: {
            width: '100%',
            height: '400px',
          },
        },
        coords: [56.48464, 84.947649],
        zoom: 13,
        settings: {
          apiKey: '3ce74472-614d-48ae-be3b-43d1739a0368',
          lang: 'ru_RU',
          coordorder: 'latlong',
          version: '2.1',
        },
        markerIcon: {
          layout: 'default#image',
          imageHref: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzciIHZpZXdCb3g9IjAgMCAzNyAzNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjAzMjU0M19kKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTguNSAyNS45MzI1QzIzLjc0NjcgMjUuOTMyNSAyOCAyMS42OTQzIDI4IDE2LjQ2NjJDMjggMTEuMjM4MiAyMy43NDY3IDcgMTguNSA3QzEzLjI1MzMgNyA5IDExLjIzODIgOSAxNi40NjYyQzkgMjEuNjk0MyAxMy4yNTMzIDI1LjkzMjUgMTguNSAyNS45MzI1WiIgZmlsbD0iIzA4ODM1OCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE4LjUgMjUuOTMyNUMyMy43NDY3IDI1LjkzMjUgMjggMjEuNjk0MyAyOCAxNi40NjYyQzI4IDExLjIzODIgMjMuNzQ2NyA3IDE4LjUgN0MxMy4yNTMzIDcgOSAxMS4yMzgyIDkgMTYuNDY2MkM5IDIxLjY5NDMgMTMuMjUzMyAyNS45MzI1IDE4LjUgMjUuOTMyNVoiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwMzI1NDNfZCIgeD0iMCIgeT0iMCIgd2lkdGg9IjM3IiBoZWlnaHQ9IjM2LjkzMjUiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQ29sb3JNYXRyaXggaW49IlNvdXJjZUFscGhhIiB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMTI3IDAiLz4KPGZlT2Zmc2V0IGR5PSIyIi8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjQiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMC4wMzEzNzI2IDAgMCAwIDAgMC41MTM3MjYgMCAwIDAgMCAwLjM0NTA5OCAwIDAgMCAwLjUgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvdyIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvdyIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4=',
          imageSize: [37, 37],
          imageOffset: [-18, -18],
        },
        title: 'Добавление объекта',
      };
    },
    methods: {
      changePreview(previews) {
        this.previews = previews;
      },
      changeFile(files) {
        this.files = files;
      },
      changeIndexCity(id) {
        this.objectCityId = id;
      },
      changeIndexType(id) {
        this.objectTypeId = id;
      },
      checkRequiredInfo() {
        if (this.objectTitle.length > 0
          && this.objectDescription.length > 0
          && this.objectTypeId !== null) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
      previewsToString() {
        let string = '';
        this.previews.forEach((item, i, array) => {
          string += `${i === 0 ? '[' : ''}"${item}"${i !== array.length - 1 ? ',' : ']'}`;
        });
        return string;
      },
      addObject() {
        let place = '?';
        if (this.objectTitle) place += `title=${this.objectTitle}`;
        if (this.objectDescription) place += `&description=${this.objectDescription}`;
        if (this.objectAnnotation) place += `&annotation=${this.objectAnnotation}`;
        if (this.objectTypeId) place += `&type_id=${this.objectTypeId}`;
        if (this.objectAddress) place += `&address=${this.objectAddress}`;
        place += `&latitude=${this.coords[0]}`;
        place += `&longitude=${this.coords[1]}`;
        if (this.objectContacts) place += `&contacts_text=${this.objectContacts}`;
        place += '&contacts_label=Контакты';
        if (this.objectYouTube) place += `&youtube=${this.objectYouTube}`;
        if (this.previews.length > 0) place += `&images[]=${this.previewsToString()}`;
        if (this.objectCityId) place += `&city_id=${this.objectCityId}`;
        if (this.$popup && this.$popup.additions && this.$popup.additions.id) {
          this.$putAddObject(place, this.$popup.additions.id);
        } else {
          this.$postAddObject(place);
        }
        this.objectTitle = '';
        this.objectDescription = '';
        this.objectAnnotation = '';
        this.objectAddress = '';
        this.objectContacts = '';
        this.objectYouTube = '';
      },
      onAddressInput(e) {
        this.objectAddress = e.target.value;
        const _this = this;

        ymaps.geocode(this.objectAddress)
          .then((res) => {
            const geoObject = res.geoObjects.get(0);
            const geoCoords = geoObject.geometry.getCoordinates();

            _this.coords = JSON.parse(`[${geoCoords}]`);
          });
      },
      onMapClick(e) {
        this.coords = e.get('coords');

        ymaps.geocode(e.get('coords'))
          .then((result) => {
            const currentLocality = result.geoObjects.get(0)
              .properties
              .get('metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea.SubAdministrativeArea.Locality.LocalityName');
            const currentAddress = result.geoObjects.get(0).properties._data.name;

            let address = '';
            if (currentLocality && currentLocality !== currentAddress) {
              address = `${currentLocality}, ${currentAddress}`;
            } else {
              address = currentAddress;
            }

            this.objectAddress = address;
          });
      },
    },
    computed: {
      width() {
        return this.params && this.params.sizeMap && this.params.sizeMap.width ? this.params.sizeMap.width : '100%';
      },
      height() {
        return this.params && this.params.sizeMap && this.params.sizeMap.height ? this.params.sizeMap.height : '100%';
      },
    },
    created() {
      if (this.$popup && this.$popup.additions && this.$popup.additions.id) {
        if (this.$popup.additions.title) this.objectTitle = this.$popup.additions.title;
        if (this.$popup.additions.description) this.objectDescription = this.$popup.additions.description;
        if (this.$popup.additions.annotation) this.objectAnnotation = this.$popup.additions.annotation;
        if (this.$popup.additions.address) this.objectAddress = this.$popup.additions.address;
        if (this.$popup.additions.city_id) this.objectCityId = this.$popup.additions.city_id;
        if (this.$popup.additions.type.id) this.objectTypeId = this.$popup.additions.type.id;
        if (this.$popup.additions.youtube) this.objectYouTube = this.$popup.additions.youtube;
        this.title = 'Редактирование объекта';
      }
      this.coords = this.params && this.params.coords ? this.params.coords : this.coords;
      this.zoom = this.params && this.params.zoom ? this.params.zoom : this.zoom;
    },
    watch: {
      objectYouTube() {
        this.errorObjectYouTube = '';
        const regexp = new RegExp('^(?:http(?:s)?:\\/\\/)?(?:www\\.)?(?:m\\.)?(?:youtu\\.be\\/|youtube\\.com\\/(?:(?:watch)?\\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\\/))([^\\?&"\'>]+)');
        if (!this.objectYouTube.match(regexp) && this.objectYouTube !== '') {
          this.errorObjectYouTube = 'Введите корректный линк';
        }
      },
      objectTitle() {
        this.checkRequiredInfo();
      },
      objectDescription() {
        this.checkRequiredInfo();
      },
      objectTypeId() {
        this.checkRequiredInfo();
      },
    },
  };
</script>
